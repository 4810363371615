<template>
  <b-card class="bg-light rounded">
    <b-card-text class="text-center">
      <u><b-link
        :to="{ name: 'apps-sample-requests-edit', params: { id: sample.id } }"
        class="text-secondary font-weight-bold"
      >
        {{ sample.sample_type }}
      </b-link></u>
    </b-card-text>
    <b-card-text class="text-center">
      <b-dropdown
        v-if="setSampleRequestStatus"
        :text="sample.sample_status"
        :disabled="!sample.sample_status_options.length"
      >
        <b-dropdown-item
          v-for="option in sample.sample_status_options"
          :key="sample.id + option"
          @click="setSampleRequestStatus(sample.id, option)"
        >
          <span class="align-middle ml-50">{{ option }}</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-card-text>
    <b-card-text class="small-card-text">
      <feather-icon
        icon="CalendarIcon"
        size="12"
      /> Deadline {{ sample.deadline }}
    </b-card-text>
    <b-card-text class="small-card-text">
      <feather-icon
        icon="CalendarIcon"
        size="12"
      /> ETD {{ sample.estimate }}
    </b-card-text>
    <b-card-text class="small-card-text">
      <approval-label
        label="Measurement"
        :approved="sample.measurement_approved"
      />
      <approval-label
        label="Design Construction"
        :approved="sample.design_approved"
      />
      <approval-label
        label="Embellishment"
        :approved="sample.embellishment_approved"
        :not-applicable="sample.embellishment_not_applicable"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BDropdown, BDropdownItem, BLink,
} from 'bootstrap-vue'

import ApprovalLabel from '../../style/styles-edit/ApprovalLabel.vue'

export default {
  components: {
    ApprovalLabel,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BLink,
  },
  props: {
    sample: {
      type: Object,
      required: true,
    },
    setSampleRequestStatus: {
      type: Function,
      default: null,
    },
  },
}
</script>
<style lang="css">
.small-card-text {
  font-size: 12px;
}
</style>
