<template>
  <div>
    <section class="grid-view">
      <b-container>
        <b-row>
          <template
            v-for="media in styleMedia"
          >
            <b-col
              v-if="!(isBrand && media.is_internal)"
              :key="media.id"
              no-body
              class="file-card-box"
            >
              <b-card>
                <b-card-text
                  text-tag="div"
                >
                  <div :class="media.is_cover ? 'image-file iscover' : 'image-file'">
                    <b-img
                      :alt="`${media.name}`"
                      rounded
                      :blank="!media.image_medium"
                      blank-color="#f2f2f2"
                      :src="media.image_medium"
                    />
                    <div
                      class="media-name"
                      :title="media.name"
                    >
                      {{ media.name }}
                    </div>
                  </div>
                  <div class="mt-1 d-flex flex-row align-items-center">

                    <!-- Info Note -->
                    <feather-icon
                      v-if="media.info_note"
                      v-b-tooltip.hover
                      icon="InfoIcon"
                      class="mr-1"
                      :title="media.info_note"
                    />

                    <!-- POM -->
                    <b-badge
                      v-if="media.is_pom"
                      pill
                      variant="secondary"
                      class="mt-1 mb-1"
                    >
                      POM
                    </b-badge>

                    <!-- Tag -->
                    <b-badge
                      v-for="tag in splitTag(media.tag)"
                      :key="tag"
                      pill
                      variant="secondary"
                      class="mt-1 mb-1"
                    >{{ tag }}</b-badge>

                    <!-- Action Buttons -->
                    <b-dropdown
                      variant="link"
                      dropup
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreHorizontalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <template
                        v-if="media.file"
                      >
                        <b-dropdown-item
                          @click="downloadFile(media.file, media.name)"
                        >Download
                        </b-dropdown-item>
                        <b-dropdown-item
                          :href="media.file"
                          target="blank"
                        >View
                        </b-dropdown-item>
                      </template>
                      <b-dropdown-item
                        v-if="media.image_medium && !media.file"
                        :href="media.image"
                        target="blank"
                      >View Original
                      </b-dropdown-item>
                      <b-dropdown-item
                        variant="danger"
                        :disabled="!canEditStyle"
                      >
                        <div
                          @click="removeMedia(media.id)"
                        >Remove File</div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="media.image_medium"
                        :disabled="!canEditStyle"
                      >
                        <div
                          @click="setCover(media.id, !media.is_cover)"
                        >
                          {{ media.is_cover ? "Uncover Style" : "Set as Style Cover" }}
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :disabled="!canEditStyle"
                      >
                        <div
                          @click="setPOM(media.id, !media.is_pom)"
                        >
                          {{ media.is_pom ? "Unset as POM" : "Set as POM" }}
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :disabled="!canEditStyle"
                      >
                        <div
                          @click="onMediaSelect(media.id, media.name, media.info_note)"
                        >
                          Info Note
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-card-text>
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-container>
    </section>
    <div class="d-flex justify-content-center">
      <b-jumbotron
        bg-variant="light"
        class="justify-content-center w-75 m-5"
      >
        <b-form-file
          v-model="files"
          multiple
          placeholder="Choose files or drop them here..."
          size="lg"
          :disabled="!canEditStyle"
          @input="filesUpload"
        />
      </b-jumbotron>
    </div>

    <b-modal
      v-model="showInfoNoteForm"
      ok-only
      ok-title="Save"
      :title="selectedMediaName"
      @ok="setInfoNote"
    >
      <b-form
        class="p-2"
      >
        <b-form-group
          label="Info Note"
          label-for="info-note"
        >
          <b-form-textarea
            id="info-note"
            v-model="infoNote"
            trim
            rows="3"
            max-rows="5"
            focus
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'
import {
  BBadge,
  BCard,
  BCardText,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BImg,
  BJumbotron,
  BModal,
  BRow,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BBadge,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormFile,
    BFormGroup,
    BFormTextarea,
    BImg,
    BJumbotron,
    BModal,

    BRow,
  },
  props: {
    styleMedia: {
      type: Array,
      required: true,
    },
    canEditStyle: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  setup(props, { emit }) {
    const toast = useToast()
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isBrand = userRole === 'brand-manager' || userRole === 'brand'

    const files = ref([])
    const showInfoNoteForm = ref(false)
    const selectedMediaId = ref(null)
    const selectedMediaName = ref('')
    const infoNote = ref('')

    const filesUpload = () => {
      if (files.value) {
        const styleMediaUpload = new FormData()
        files.value.forEach(upload => {
          if (upload.size > 25 * 1024 * 1024) {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              // transition settings are ignored :-P
              transitionDuration: 10000,
              transition: 10000,
              props: {
                title: `File ${upload.name} too big (> 25MB)`,
                icon: 'InfoIcon',
                variant: 'danger',
              },
            })
          } else {
            styleMediaUpload.append('files', upload)
          }
        })
        if (styleMediaUpload) {
          store.dispatch('app-style/addStyleMedia', {
            id: router.currentRoute.params.id,
            styleMediaUpload,
          })
            .then(() => {
              emit('fetch-style-media')
              files.value = []
            })
        }
      }
    }

    const removeMedia = id => {
      store.dispatch('app-style/removeStyleMedia', { id })
        .then(() => {
          emit('fetch-style-media')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'File removed',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const setCover = (id, isCover) => {
      store.dispatch('app-style/updateStyleMedia', { id, is_cover: isCover })
        .then(() => {
          emit('fetch-style-media')
        })
    }

    const setPOM = (id, isPOM) => {
      store.dispatch('app-style/updateStyleMedia', { id, is_pom: isPOM })
        .then(() => {
          emit('fetch-style-media')
        })
    }

    const onMediaSelect = (id, name, note) => {
      selectedMediaId.value = id
      selectedMediaName.value = name
      infoNote.value = note
      showInfoNoteForm.value = true
    }

    const setInfoNote = () => {
      store.dispatch('app-style/updateStyleMedia', {
        id: selectedMediaId.value,
        info_note: infoNote.value,
      })
        .then(() => {
          showInfoNoteForm.value = false
          emit('fetch-style-media')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Info Note saved',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const splitTag = tag => {
      if (!tag) {
        return []
      }
      return tag.split(',')
    }

    const downloadFile = (fileUrl, fileName) => {
      axios.get(fileUrl, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob(
            [response.data], { type: response.headers['content-type'] },
          )
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
    }

    return {
      filesUpload,
      removeMedia,
      setCover,
      setPOM,
      onMediaSelect,
      setInfoNote,
      splitTag,
      downloadFile,

      files,
      isBrand,
      showInfoNoteForm,
      selectedMediaId,
      selectedMediaName,
      infoNote,
    }
  },
}
</script>

<style lang="scss" scoped>
.file-card-box {
  flex: 0 1 300px;
  overflow:hidden;
}
.image-file img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  vertical-align: middle;
  border-radius: 15px;
}
.image-file .nopreview {
  object-fit: cover;
  width: 100%;
  display:flex;
}
.noimage-file {
  margin: 0 auto 0 auto;
  height: 180px;
  border-radius: 15px;
}
.iscover {
  outline:10px solid;
  border-radius: 15px;
}
.media-name {
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
