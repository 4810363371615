<template>
  <div>
    <div
      v-if="seePrices && fabrics.length"
    >
      <h4>Fabrics</h4>
      <b-table-simple
        class="mb-4"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="p-1 w-25"
            >Category type</b-th>
            <b-th
              class="p-1"
            >Description</b-th>
            <b-th
              class="p-1 w-25"
            >Price</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="material in fabrics"
            :key="material.id"
          >
            <b-td
              class="p-1"
            >{{ material.category_type }}</b-td>
            <b-td
              class="p-1"
            >
              <div class="align-text-top">
                {{ material.specifics_description }}
              </div>
              <div class="align-text-top">
                {{ material.dimensions_description }}
              </div>
            </b-td>
            <b-td class="p-1">
              <div
                v-if="material.consumption.quantity && material.price"
              >
                {{ material.consumption.quantity }} x {{ material.price }}
                {{ material.consumption.quantity_units && '// ' }}{{ material.consumption.quantity_units }}
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div
      v-if="seePrices && trims.length"
    >
      <h4>Trims</h4>
      <b-table-simple
        class="mb-4"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="p-1 w-25"
            >Category type</b-th>
            <b-th
              class="p-1"
            >Description</b-th>
            <b-th
              class="p-1 w-25"
            >Price</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="material in trims"
            :key="material.id"
          >
            <b-td
              class="p-1"
            >{{ material.category_type }}</b-td>
            <b-td
              class="p-1"
            >
              <div class="align-text-top">
                {{ material.specifics_description }}
              </div>
              <div class="align-text-top">
                {{ material.dimensions_description }}
              </div>
            </b-td>
            <b-td class="p-1">
              <div
                v-if="material.consumption.quantity && material.price"
              >
                {{ material.consumption.quantity }} x {{ material.price }}
                {{ material.consumption.quantity_units && '// ' }}{{ material.consumption.quantity_units }}
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div
      v-if="seePrices && embellishments.length"
    >
      <h4>Embellishments</h4>
      <b-table-simple
        class="mb-4"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="p-1 w-25"
            >Category type</b-th>
            <b-th
              class="p-1"
            >Description</b-th>
            <b-th
              class="p-1 w-25"
            >Price</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="embellishment in embellishments"
            :key="embellishment.id"
          >
            <b-td
              class="p-1"
            >{{ embellishment.category_type }}</b-td>
            <b-td
              class="p-1"
            >
              <div class="align-text-top">
                {{ embellishment.specifics_description }}
              </div>
              <div class="align-text-top">
                {{ embellishment.dimensions_description }}
              </div>
            </b-td>
            <b-td
              class="p-1 w-25"
            >{{ embellishment.price }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div
      v-if="seePrices && packaging.length"
    >
      <h4>Packaging</h4>
      <b-table-simple
        class="mb-4"
      >
        <b-thead>
          <b-tr>
            <b-th
              class="p-1 w-25"
            >Category type</b-th>
            <b-th
              class="p-1"
            >Description</b-th>
            <b-th
              class="p-1 w-25"
            >Price</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="material in packaging"
            :key="material.id"
          >
            <b-td
              class="p-1"
            >{{ material.category_type }}</b-td>
            <b-td
              class="p-1"
            >
              <div class="align-text-top">
                {{ material.specifics_description }}
              </div>
              <div class="align-text-top">
                {{ material.dimensions_description }}
              </div>
            </b-td>
            <b-td class="p-1">
              <div
                v-if="material.consumption.quantity && material.price"
              >
                {{ material.consumption.quantity }} x {{ material.price }}
                {{ material.consumption.quantity_units && '// ' }}{{ material.consumption.quantity_units }}
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <div
      v-if="seePrices"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >
        <!-- Price internal notes -->
        <b-form-group
          label="Price internal notes"
          label-for="price-internal-notes"
        >
          <b-form-textarea
            id="price-internal-notes"
            v-model="formData.price_internal_notes"
            trim
            rows="3"
            max-rows="5"
            :disabled="!canEditStyle"
          />
        </b-form-group>

        <!-- Price notes -->
        <b-form-group
          label="Price notes"
          label-for="price-notes"
        >
          <b-form-textarea
            id="price-notes"
            v-model="formData.price_notes"
            trim
            rows="3"
            max-rows="5"
            :disabled="!canEditStyle"
          />
        </b-form-group>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="!canEditStyle"
        >
          Save
        </b-button>
        <b-button
          variant="secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="!canEditStyle"
          @click="showCommentsForm = true"
        >
          Add comment
        </b-button>
      </b-form>
    </div>

    <!-- Brand Price Notes -->
    <div
      v-else
    >
      <h4>Price Notes</h4>
      <div
        style="white-space: pre-line"
      >{{ priceNotes }}
      </div>

      <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="!canEditStyle"
        @click="showCommentsForm = true"
      >
        Add comment
      </b-button>
    </div>

    <b-modal
      v-model="showCommentsForm"
      ok-only
      ok-title="Add"
      :ok-disabled="!comments.trim()"
      title="Add comment"
      @ok="addComments"
    >
      <b-form
        class="p-2"
      >
        <b-form-group
          label="Comment"
          label-for="comment"
        >
          <b-form-textarea
            id="comment"
            v-model="comments"
            trim
            rows="3"
            max-rows="5"
            required
            autofocus
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BModal, BFormTextarea,
  BTableSimple, BTbody, BTd, BTh, BThead, BTr,
} from 'bootstrap-vue'

import {
  onMounted, onUnmounted, ref,
} from '@vue/composition-api'

import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import styleStoreModule from '../styleStoreModule'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
    BModal,
    BTableSimple,
    BTbody,
    BTd,
    BTh,
    BThead,
    BTr,
  },
  props: {
    canEditStyle: {
      type: Boolean,
      required: true,
    },
    priceInternalNotes: {
      type: String,
      required: true,
      default: '',
    },
    priceNotes: {
      type: String,
      required: true,
    },
    styleData: {
      type: Object,
      required: true,
    },
  },
  emits: ['fetch-style', 'scroll-to-bottom'],
  setup(props, { emit, root }) {
    const STYLE_APP_STORE_MODULE_NAME = 'app-style'

    // Register module
    if (!root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      root.$store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        root.$store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const seePrices = userRole === 'manufacturer-manager' || userRole === 'manufacturer' || userRole === 'platform-manager'

    const fabrics = ref([])
    const trims = ref([])
    const embellishments = ref([])
    const packaging = ref([])

    const formData = ref({
      price_internal_notes: props.priceInternalNotes,
      price_notes: props.priceNotes,
    })
    const showCommentsForm = ref(false)
    const comments = ref('')

    const onSubmit = () => {
      const styleData = {
        id: router.currentRoute.params.id,
        ...formData.value,
      }
      store.dispatch('app-style/editStyle', styleData)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Prices edited',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const fetchStyleMaterials = () => {
      if (!seePrices) {
        return
      }

      const ordering = '-id'
      store
        .dispatch('app-style/fetchStyleFabrics', {
          id: router.currentRoute.params.id,
          ordering,
        })
        .then(response => {
          fabrics.value = [...response.data.style_material]
        })
        .catch(error => {
          if (error.response.status === 404) {
            fabrics.value = []
          }
        })
      store
        .dispatch('app-style/fetchStyleTrims', {
          id: router.currentRoute.params.id,
          ordering,
        })
        .then(response => {
          trims.value = [...response.data.style_material]
        })
        .catch(error => {
          if (error.response.status === 404) {
            trims.value = []
          }
        })
      store
        .dispatch('app-style/fetchStylePackaging', {
          id: router.currentRoute.params.id,
          ordering,
        })
        .then(response => {
          packaging.value = [...response.data.style_material]
        })
        .catch(error => {
          if (error.response.status === 404) {
            packaging.value = []
          }
        })
    }

    const fetchStyleEmbellishments = () => {
      if (!seePrices) {
        return
      }

      const ordering = '-id'
      store
        .dispatch('app-style/fetchStyleEmbellishments', {
          id: router.currentRoute.params.id,
          ordering,
        })
        .then(response => {
          embellishments.value = [...response.data.style_material]
        })
        .catch(error => {
          if (error.response.status === 404) {
            embellishments.value = []
          }
        })
    }

    const addComments = () => {
      const feedData = new FormData()
      feedData.append('style', props.styleData.id)
      feedData.append('type', 'Comment')
      feedData.append('feed_vars', JSON.stringify({
        comments: comments.value,
        custom_type: 'Prices',
      }))
      store.dispatch('app-style/addStyleFeed', feedData)
        .then(() => {
          emit('fetch-style')
          comments.value = ''
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Comment added',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          emit('scroll-to-bottom')
        })
    }

    onMounted(fetchStyleMaterials)
    onMounted(fetchStyleEmbellishments)

    return {
      showCommentsForm,
      comments,
      seePrices,
      fabrics,
      trims,
      embellishments,
      packaging,
      formData,

      onSubmit,
      addComments,
    }
  },
}
</script>
