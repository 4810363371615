<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          md="8"
        >
          <h4>Sizes</h4>
          <b-row v-show="!gridHasSizes()">
            <!-- Field: Size Range -->
            <b-col
              md="8"
            >
              <b-form-group
                label="Select Size Range"
                label-for="style-size-range"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sizeRangeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :disabled="!canEditStyle"
                  input-id="style-size-range"
                  @input="selectSizeRange"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <draggable
                v-model="gridSizes"
                @change="gridModified = !gridModified"
              >
                <b-button
                  v-for="size in gridSizes"
                  :key="size.key"
                  pill
                  :data-size="size.key"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="!canEditStyle"
                  @click="sizeClick"
                >
                  {{ size.key.toUpperCase() }}
                </b-button>
              </draggable>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-1">
            <b-col
              md="2"
            >
              <b-form-input
                v-model="newSize"
                trim
                :disabled="!canEditStyle"
              />
            </b-col>
            <b-col
              md="2"
            >
              <b-button
                :disabled="!canEditStyle"
                @click="addSize"
              >Add other Size</b-button>
            </b-col>
          </b-row>

          <b-row v-show="gridHasSizes()">

            <!-- Field: Sample Size -->
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                label="Define Sample Size"
                label-for="style-sample-size"
              >
                <v-select
                  v-model="sampleSize"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sampleSizeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :disabled="!canEditStyle"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-show="gridHasSizes()"
            class="mt-3"
          >
            <b-col>
              <h4>POM Table
                <b-button
                  v-show="showDeleteRows"
                  class="ml-1"
                  size="sm"
                  variant="danger"
                  @click="deleteRows"
                >Delete selected rows</b-button>
              </h4>
              <vue-excel-editor
                ref="refExcelEditor"
                :key="gridModified"
                v-model="sizeData"
                :readonly="!canEditStyle"
                no-paging
                no-header-edit
                no-footer
                disable-panel-filter
                disable-panel-setting
                :spellcheck="false"
                new-if-bottom
                enter-to-south
              >
                <vue-excel-column
                  field="ref"
                  label="REF"
                  width="50px"
                  type="string"
                  :sort="() => null"
                  :init-style="{fontWeight: 'bold', textAlign: 'right'}"
                />
                <vue-excel-column
                  field="description"
                  label="Measurement"
                  width="200px"
                  type="string"
                  :sort="() => null"
                />
                <vue-excel-column
                  field="tol"
                  label="Tol (+/-)"
                  width="50px"
                  type="string"
                  :sort="() => null"
                />
                <template v-for="col in gridSizes">
                  <vue-excel-column
                    v-if="!col.hide"
                    :key="col.key"
                    :field="col.field"
                    :label="col.label"
                    :width="col.width"
                    type="string"
                    :init-style="col.field === sampleSize ? {
                      fontWeight: 'bold', backgroundColor: '#e9ecef'
                    } : {}"
                    :sort="() => null"
                  />
                </template>
              </vue-excel-editor>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="4"
        >
          <b-row>
            <b-col>
              <div
                v-if="poms.length > 0"
                id="pom-images"
              >
                <div
                  v-for="(media, index) in poms"
                  :key="media.id"
                  class="d-flex flex-row justify-content-end pom-image-container"
                >
                  <b-img
                    rounded
                    class="pom-image"
                    :src="media.image"
                  />
                  <b-dropdown
                    variant="link"
                    no-caret
                    class="pom-image-dropdown"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="16"
                        class="align-middle text-body"
                        :style="{ stroke: 'white' }"
                      />
                    </template>
                    <b-dropdown-item>
                      <div
                        @click="unsetPOM(media.id)"
                      >Unset as POM</div>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="index != 0"
                    >
                      <div
                        @click="moveUp(index)"
                      >Move Up</div>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="index < poms.length - 1"
                    >
                      <div
                        @click="moveDown(index)"
                      >Move Down</div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="mt-1"
          >
            <b-col
              md="8"
            >
              <b-form-file
                v-model="pomImageFile"
                placeholder="Upload a POM Image"
                size="sm"
                :disabled="!canEditStyle"
              />
            </b-col>
          </b-row>
          <b-row
            class="mt-1"
          >
            <b-col
              md="8"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="!canEditStyle"
                @click="onSubmit"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>

    <div>
      <b-row
        v-if="!isBrand"
        class="mt-1"
      >
        <b-col
          md="8"
        >
          <h4>Construction</h4>
          <b-form-group
            label="Construction Details"
            label-for="construction-details"
          >
            <b-form-textarea
              id="construction-details"
              v-model="constructionDetails"
              trim
              rows="3"
              max-rows="5"
              :disabled="!canEditStyle"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        class="mt-2"
      >
        <b-col>
          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="!canEditStyle"
            @click="onSubmit"
          >
            Save
          </b-button>
          <b-button
            variant="secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="!canEditStyle"
            @click="showCommentsForm = true"
          >
            Add comment
          </b-button>
        </b-col>
      </b-row>
    </div>

    <b-modal
      v-model="showCommentsForm"
      ok-only
      ok-title="Add"
      :ok-disabled="!comments.trim()"
      title="Add comment"
      @ok="addComments"
    >
      <b-form
        class="p-2"
      >
        <b-form-group
          label="Comment"
          label-for="comment"
        >
          <b-form-textarea
            id="comment"
            v-model="comments"
            trim
            rows="3"
            max-rows="5"
            required
            autofocus
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCol, BForm, BFormFile, BDropdown, BDropdownItem, BFormGroup,
  BFormInput, BFormTextarea, BImg, BModal, BRow,
} from 'bootstrap-vue'
import { ref, computed, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import draggable from 'vuedraggable'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTextarea,
    BImg,
    BModal,
    vSelect,
    draggable,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
    canEditStyle: {
      type: Boolean,
      required: true,
    },
    styleMedia: {
      type: Array,
      required: true,
    },
    sizeRangeOptions: {
      type: Array,
      required: true,
    },
  },
  emits: ['fetch-style', 'scroll-to-bottom'],
  data() {
    return {}
  },
  setup(props, { emit }) {
    const toast = useToast()
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isBrand = userRole === 'brand-manager' || userRole === 'brand'

    const pomImageFile = ref(null)
    const gridModified = ref(false)
    const sizeData = ref(props.styleData.sizes)
    const newSize = ref('Z')
    const gridSizes = ref(props.styleData.sizes_ordering.map(size => ({
      key: size,
      field: size.toUpperCase(),
      label: size.toUpperCase(),
      width: '51px',
    })))
    const sampleSize = ref(props.styleData.sample_size)
    const constructionDetails = ref(props.styleData.construction_details)
    const showCommentsForm = ref(false)
    const comments = ref('')
    const refExcelEditor = ref(null)

    const sampleSizeOptions = computed(() => (
      gridSizes.value.map(size => (
        { label: size.field, value: size.field }))
    ))

    const poms = computed(() => (
      props.styleMedia.filter(media => media.is_pom === true)))

    const showDeleteRows = computed(() => {
      if (refExcelEditor.value) {
        const { selectedCount } = refExcelEditor.value
        const totalRows = refExcelEditor.value.table.length
        return selectedCount > 0 && selectedCount < totalRows
      }
      return false
    })

    watch([sampleSize, gridSizes, sizeData], () => {
      gridModified.value = !gridModified.value
    })

    const selectSizeRange = selectedSizeRange => {
      gridSizes.value = selectedSizeRange.map(col => (
        {
          key: col,
          field: col.toUpperCase(),
          label: col.toUpperCase(),
          width: '51px',
        }
      ))

      const columns = ['ref', 'description', ...selectedSizeRange]
      sizeData.value = [columns.reduce((accumulator, size) => (
        { ...accumulator, [size]: '', tol: '0.5' }), {})]
    }

    const sizeClick = event => {
      const button = event.currentTarget
      const size = button.getAttribute('data-size')

      gridSizes.value = gridSizes.value.filter(col => col.field !== size)
      sizeData.value = sizeData.value.map(row => {
        const { [size]: remove, ...rest } = row
        return rest
      })
    }

    const gridHasSizes = () => gridSizes.value.length > 0

    const addSize = () => {
      if (newSize.value) {
        const size = newSize.value.toUpperCase()
        if (!gridSizes.value.some(elem => elem.key === size)) {
          gridSizes.value = [...gridSizes.value, {
            key: size, field: size, label: size, width: '51px',
          }]
        }
      }
    }

    const uploadMedia = styleMediaUpload => {
      store.dispatch('app-style/addStyleMedia', {
        id: router.currentRoute.params.id,
        styleMediaUpload,
      })
        .then(() => {
          emit('fetch-style-media')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Image uploaded',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          pomImageFile.value = null
        })
    }

    const onSubmit = () => {
      const sizes = sizeData.value.map(row => {
        const cols = Object.keys(row).filter(key => !key.includes('$id'))
        const filteredRow = {}
        cols.forEach(col => {
          filteredRow[col] = row[col]
        })
        return filteredRow
      })
      const sizesOrdering = gridSizes.value.map(size => size.field)
      const sizeInfo = {
        id: router.currentRoute.params.id,
        sizes,
        sizes_ordering: sizesOrdering,
        sample_size: sampleSize.value,
      }
      if (!isBrand) {
        sizeInfo.construction_details = constructionDetails.value
      }
      store.dispatch('app-style/editStyle', sizeInfo)
        .then(response => {
          emit('fetch-style')
          sizeData.value = response.data.sizes
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sizes saved',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })

      if (pomImageFile.value) {
        const styleMediaUpload = new FormData()
        styleMediaUpload.append('files', pomImageFile.value)
        styleMediaUpload.append('is_pom', 'true')
        uploadMedia(styleMediaUpload)
      }
    }

    const addComments = () => {
      const feedData = new FormData()
      feedData.append('style', props.styleData.id)
      feedData.append('type', 'Comment')
      feedData.append('feed_vars', JSON.stringify({
        comments: comments.value,
        custom_type: 'Sizes and Measurements',
      }))
      store.dispatch('app-style/addStyleFeed', feedData)
        .then(() => {
          emit('fetch-style')
          comments.value = ''
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Comment added',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          emit('scroll-to-bottom')
        })
    }

    const deleteRows = () => {
      if (refExcelEditor.value) {
        refExcelEditor.value.deleteSelectedRecords()
      }
    }

    const move = (from, to) => {
      store.dispatch('app-style/switchStyleMedia', {
        id: from, other: to,
      }).then(() => {
        emit('fetch-style-media')
        toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'POM image moved',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
      })
    }

    const moveUp = itemIndex => {
      const currentItem = poms.value[itemIndex]
      const otherItem = poms.value[itemIndex - 1]
      move(currentItem.id, otherItem.id)
    }

    const moveDown = itemIndex => {
      const currentItem = poms.value[itemIndex]
      const otherItem = poms.value[itemIndex + 1]
      move(currentItem.id, otherItem.id)
    }

    const unsetPOM = id => {
      store.dispatch('app-style/updateStyleMedia', { id, is_pom: false })
        .then(() => {
          emit('fetch-style-media')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Unset as POM',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    return {
      showCommentsForm,
      comments,
      isBrand,
      onSubmit,
      selectSizeRange,
      sizeClick,
      gridHasSizes,
      addSize,
      addComments,
      showDeleteRows,
      deleteRows,
      moveUp,
      moveDown,
      unsetPOM,
      sampleSizeOptions,

      refExcelEditor,
      sizeData,
      poms,
      newSize,
      gridSizes,
      gridModified,
      sampleSize,
      pomImageFile,
      constructionDetails,
    }
  },
}
</script>

<style lang="scss" scoped>
#pom-images {
  height: 600px;
  overflow-y: auto;
}
.pom-image {
  height: auto;
  max-width: 100%;
}
.pom-image-container {
  position: relative;
}
.pom-image-dropdown {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.pom-image-container:hover .pom-image-dropdown {
 display: block;
}
.pom-image-container:hover img {
 filter: brightness(50%);
}
</style>
