<template>
  <validation-observer
    #default="{ invalid }"
    ref="refFormObserver"
  >
    <b-modal
      ok-only
      ok-title="Save"
      :ok-disabled="invalid"
      :title="materialTitle"
      :visible="showStatusForm"
      @ok="setStatusAndDates"
      @hidden="closeStatusForm"
    >
      <b-form
        class="p-2"
      >
        <b-form-group
          label="Status"
          label-for="status"
        >
          <b-form-select
            v-model="status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="workflowStatusOptions.map(status => status.value)"
            input-id="status"
          />
        </b-form-group>

        <validation-provider
          v-if="requiredDates.includes('requested_date')"
          #default="validationContext"
          name="Requested Date"
          rules="required"
        >
          <b-form-group
            label="Requested Date"
            label-for="requested-date"
          >
            <b-form-datepicker
              v-model="requestedDate"
              input-id="requested-date"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="requiredDates.includes('deadline_date')"
          #default="validationContext"
          name="Deadline Date"
          rules="required"
        >
          <b-form-group
            label="Deadline Date"
            label-for="deadline-date"
          >
            <b-form-datepicker
              v-model="deadlineDate"
              input-id="deadline-date"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="requiredDates.includes('sent_date')"
          #default="validationContext"
          name="Sent Date"
          rules="required"
        >
          <b-form-group
            label="Sent Date"
            label-for="sent-date"
          >
            <b-form-datepicker
              v-model="sentDate"
              input-id="sent-date"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="requiredDates.includes('approved_date')"
          #default="validationContext"
          name="Approved Date"
          rules="required"
        >
          <b-form-group
            label="Approved Date"
            label-for="approved-date"
          >
            <b-form-datepicker
              v-model="approvedDate"
              input-id="approved-date"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          v-if="requiredDates.includes('rejected_date')"
          #default="validationContext"
          name="Rejected Date"
          rules="required"
        >
          <b-form-group
            label="Rejected Date"
            label-for="rejected-date"
          >
            <b-form-datepicker
              v-model="rejectedDate"
              input-id="rejected-date"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

      </b-form>
    </b-modal>
  </validation-observer>
</template>
<script>
import {
  BForm, BFormDatepicker, BFormGroup, BFormInvalidFeedback, BFormSelect, BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  onUnmounted, ref, watch,
} from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import styleStoreModule from '../styleStoreModule'

export default {
  components: {
    BForm,
    BFormDatepicker,
    BFormGroup,
    BFormInvalidFeedback,
    BFormSelect,
    BModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    workflowStatusOptions: {
      type: Array,
      required: true,
    },
    workflowStatusDates: {
      type: Object,
      required: true,
    },
    workflowStatus: {
      type: String,
      required: true,
    },
    showStatusForm: {
      type: Boolean,
      required: true,
    },
    selectedMaterial: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  emits: ['refetch-data', 'close-status-form'],
  setup(props, { emit, root }) {
    const STYLE_APP_STORE_MODULE_NAME = 'app-style'
    const toast = useToast()

    // Register module
    if (!root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      root.$store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        root.$store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const status = ref(props.workflowStatus)
    const materialId = ref('')
    const materialTitle = ref('')
    const requiredDates = ref([])
    const requestedDate = ref('')
    const deadlineDate = ref('')
    const sentDate = ref('')
    const approvedDate = ref('')
    const rejectedDate = ref('')

    watch([status], () => {
      requiredDates.value = props.workflowStatusDates[status.value] || []
    })

    watch(() => props.workflowStatus, newValue => {
      if (newValue !== undefined) {
        status.value = newValue
      }
    }, { immediate: true })

    watch(() => props.selectedMaterial, newValue => {
      const material = props.selectedMaterial
      const today = new Date().toISOString().split('T')[0]
      if (material !== null && newValue !== null) {
        materialId.value = newValue.id
        materialTitle.value = newValue.category_type
        if (newValue.specifics_description) {
          materialTitle.value += ` - ${newValue.specifics_description}`
        }
        requestedDate.value = newValue.requested_date || today
        deadlineDate.value = newValue.deadline_date
        sentDate.value = newValue.sent_date || today
        approvedDate.value = newValue.approved_date || today
        rejectedDate.value = newValue.rejected_date || today
      }
    }, { immediate: true })

    const closeStatusForm = () => {
      emit('close-status-form')
    }

    const setStatusAndDates = () => {
      const formData = {
        id: materialId.value,
        status: status.value,
      }
      if (requiredDates.value.includes('requested_date')) {
        formData.requested_date = requestedDate.value
      }
      if (requiredDates.value.includes('deadline_date')) {
        formData.deadline_date = deadlineDate.value
      }
      if (requiredDates.value.includes('sent_date')) {
        formData.sent_date = sentDate.value
      }
      if (requiredDates.value.includes('approved_date')) {
        formData.approved_date = approvedDate.value
      }
      if (requiredDates.value.includes('rejected_date')) {
        formData.rejected_date = rejectedDate.value
      }
      store.dispatch('app-style/updateStyleMaterial', formData)
        .then(() => {
          emit('refetch-data')
          emit('close-status-form')
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Status updated',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      setStatusAndDates,
      closeStatusForm,

      requiredDates,
      materialId,
      materialTitle,
      requestedDate,
      deadlineDate,
      sentDate,
      approvedDate,
      rejectedDate,
      status,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>
