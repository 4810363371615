import axios from '@axios'

export default {
  namespaced: true,
  state: {
    formOptions: {},
    materialOptions: {},
  },
  getters: {
    categoryOptions: state => {
      const { categories } = state.formOptions
      return categories ? categories.options : []
    },
    defaultCategory: state => {
      const { categories } = state.formOptions
      return categories ? categories.default : ''
    },
    agentOptions: state => {
      const { agents } = state.formOptions
      return agents ? agents.options : []
    },
    brandOptions: state => {
      const { brands } = state.formOptions
      return brands ? brands.options : []
    },
    manufacturerOptions: state => {
      const { manufacturers } = state.formOptions
      return manufacturers ? manufacturers.options : []
    },
    collectionOptions: state => {
      const { collections } = state.formOptions
      return collections ? collections.options : []
    },
    agentContactUserOptions: state => {
      const { agent_contact_users: users } = state.formOptions
      return users ? users.options : []
    },
    brandContactUserOptions: state => {
      const { brand_contact_users: users } = state.formOptions
      return users ? users.options : []
    },
    manufacturerContactUserOptions: state => {
      const { manufacturer_contact_users: users } = state.formOptions
      return users ? users.options : []
    },
    sizeRangeOptions: state => {
      const { size_range: sizeRange } = state.formOptions
      return sizeRange ? sizeRange.options : []
    },
    materialCategoryTypeOptions: state => {
      const { material_category_types: materialCategoryTypes } = state.formOptions
      return materialCategoryTypes ? materialCategoryTypes.options : []
    },
    materialUnitOptions: state => {
      const { material_units: materialUnits } = state.formOptions
      return materialUnits ? materialUnits.options : []
    },
    materialFabricWeightOptions: state => {
      const { material_fabric_weight: materialFabricWeight } = state.formOptions
      return materialFabricWeight ? materialFabricWeight.options : []
    },
    materialQualityOptions: state => {
      const { material_quality: materialQuality } = state.formOptions
      return materialQuality ? materialQuality.options : []
    },
    materialFabricFinishingOptions: state => {
      const { material_fabric_finishing: materialFabricFinishing } = state.formOptions
      return materialFabricFinishing ? materialFabricFinishing.options : []
    },
    colorOptions: state => {
      const { colors } = state.formOptions
      return colors ? colors.options : []
    },
    sampleTypeOptions: state => {
      const { sample_types: sampleTypes } = state.formOptions
      return sampleTypes ? sampleTypes.options : []
    },
    sampleStatusOptions: state => {
      const { sample_status: sampleStatus } = state.formOptions
      return sampleStatus ? sampleStatus.options : []
    },
    supplierOptions: state => {
      const { suppliers } = state.formOptions
      return suppliers ? suppliers.options : []
    },
  },
  mutations: {
    UPDATE_FORM_OPTIONS(state, formOptions) {
      state.formOptions = formOptions
    },
    UPDATE_MATERIAL_OPTIONS(state, formOptions) {
      state.materialOptions = formOptions
    },
  },
  actions: {
    fetchStyles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/styles/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFormOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/styles/styles_options/')
          .then(response => {
            commit('UPDATE_FORM_OPTIONS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchDraftLimitReached() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/styles/draft_limit_reached/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editStyle(ctx, styleData) {
      return new Promise((resolve, reject) => {
        const { id, ...withoutId } = styleData
        axios
          .patch(`/api/v1/styles/${id}/`, withoutId)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStyle(ctx, styleData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/styles-create/', styleData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    copyStyle(ctx, styleData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/styles-copy/', styleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStyle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/styles/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleMedia(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-media-list/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleMaterialMedia(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-material-media-list/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStyleMedia(ctx, { id, styleMediaUpload }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-media-upload/${id}/`, styleMediaUpload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeStyleMedia(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/styles-media/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStyleMedia(ctx, { id, ...styleMediaData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-media/${id}/`, styleMediaData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    switchStyleMedia(ctx, { id, other }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-media/${id}/switch/`, { other })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleMaterials(ctx, { id, ...queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-material-list/${id}/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleFabrics(ctx, { id, ...queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-fabrics-list/${id}/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleTrims(ctx, { id, ...queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-trims-list/${id}/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleEmbellishments(ctx, { id, ...queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-embellishments-list/${id}/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStylePackaging(ctx, { id, ...queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-packaging-list/${id}/`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMaterials(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/materials/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStyleMaterial(ctx, { id, ...materialData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-material-add/${id}/`, materialData, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleMaterial(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-material-update/${id}/`, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStyleMaterial(ctx, { id, ...materialData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-material-update/${id}/`, materialData, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStyleMaterial(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/styles-material-update/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStyleMaterialMedia(ctx, { id, styleMediaUpload }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-material-media-upload/${id}/`, styleMediaUpload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStyleFeed(ctx, feedData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/styles-feed-add/', feedData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleSampleRequests(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/styles-sample-request-list/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSampleRequest(ctx, { id, ...sampleRequestData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-sample-request-add/${id}/`, sampleRequestData, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSampleRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-sample-request/${id}/`, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSampleRequest(ctx, { id, ...sampleRequestData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-sample-request/${id}/`, sampleRequestData, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStyleMaterialFeed(ctx, feedData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/styles-material-feed-add/', feedData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStyleEmbellishment(ctx, { id, ...data }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-embellishment-add/${id}/`, data, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleEmbellishment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-embellishment-update/${id}/`, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStyleEmbellishment(ctx, { id, ...data }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-embellishment-update/${id}/`, data, {
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStyleEmbellishment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/styles-embellishment-update/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStyleEmbellishmentMedia(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/styles-embellishment-media-list/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStyleEmbellishmentMedia(ctx, { id, styleMediaUpload }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/styles-embellishment-media-upload/${id}/`, styleMediaUpload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStyleEmbellishmentFeed(ctx, feedData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/styles-embellishment-feed-add/', feedData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
