<template>
  <b-sidebar
    id="add-new-sample-request-sidebar"
    :visible="isAddNewSampleRequestSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :width="'85%'"
    @change="(val) => $emit('update:is-add-new-sample-request-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Sample Request
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >

          <!-- Sample Type -->
          <validation-provider
            #default="validationContext"
            name="Sample Type"
            rules="required"
          >
            <b-form-group
              label="Sample Type (required)"
              label-for="sample-type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="sampleRequestData.sample_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sampleTypeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="sample-type"
                aria-required="true"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Initial Status -->
          <b-form-group
            label="Initial Status"
            label-for="initial-status"
          >
            <b-form-select
              v-model="sampleRequestData.sample_status"
              :options="sampleStatusOptions"
              input-id="initial-status"
            />
          </b-form-group>

          <!-- Deadline Date -->
          <validation-provider
            #default="validationContext"
            name="Deadline"
            rules="required"
          >
            <b-form-group
              label="Deadline (required)"
              label-for="deadline"
              :state="getValidationState(validationContext)"
            >
              <b-form-datepicker
                v-model="sampleRequestData.deadline"
                required
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Estimate Date -->
          <b-form-group
            label="Estimate"
            label-for="estimate"
          >
            <b-form-datepicker
              v-model="sampleRequestData.estimate"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </b-form-group>

          <!-- Sample Size -->
          <b-form-group
            label="Sample Size"
            label-for="sample-size"
          >
            <b-form-select
              v-model="sampleRequestData.sample_size"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="styleData.sizes_ordering"
              input-id="sample-size"
            />
          </b-form-group>

          <!-- Sample Quantity -->
          <b-form-group
            label="Sample Quantity"
            label-for="sample-quantity"
          >
            <b-form-input
              id="sample-quantity"
              v-model="sampleRequestData.sample_quantity"
              type="number"
            />
          </b-form-group>

          <!-- Comments -->
          <b-form-group
            label="Comments"
            label-for="comments"
          >
            <b-form-textarea
              id="comments"
              v-model="sampleRequestData.comments"
              trim
              rows="3"
              max-rows="5"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BSidebar, BForm, BFormDatepicker, BFormGroup,
  BFormInput, BFormInvalidFeedback, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormSelect,
    BFormTextarea,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSampleRequestSidebarActive',
    event: 'update:is-add-new-sample-request-sidebar-active',
  },
  props: {
    isAddNewSampleRequestSidebarActive: {
      type: Boolean,
      required: true,
    },
    styleData: {
      type: Object,
      required: true,
    },
    sampleTypeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const blankSampleRequestData = {
      sample_type: '',
      sample_status: 'Requested',
      deadline: '',
      estimate: '',
      comments: '',
      sample_size: props.styleData.sample_size,
      sample_quantity: 1,
    }
    const sampleRequestData = ref(JSON.parse(JSON.stringify(blankSampleRequestData)))

    const sampleStatusOptions = ['Planned', 'Requested']

    const resetSampleRequestData = () => {
      sampleRequestData.value = JSON.parse(JSON.stringify(blankSampleRequestData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSampleRequestData)

    const onSubmit = () => {
      store.dispatch('app-style/addSampleRequest', {
        id: router.currentRoute.params.id,
        ...sampleRequestData.value,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sample Request edited',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          emit('refresh-data')
          emit('update:is-add-new-sample-request-sidebar-active', false)
          resetForm()
        })
    }

    return {
      sampleRequestData,
      sampleStatusOptions,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
