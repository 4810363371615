<template>
  <div>
    <validation-observer
      ref="refFormObserver"
      #default="{invalid}"
    >
      <!-- Style Info: Input Fields -->
      <b-form
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Field: Name -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name *"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="formData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  :disabled="!canEditStyle"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Status -->
          <b-col
            v-if="canEditStatus"
            cols="12"
            md="4"
          >
            <b-form-group
              label="Status"
              label-for="style-status"
            >
              <v-select
                v-model="formData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="workflowStatusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="style-status"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Ref -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Ref"
              rules="required"
            >
              <b-form-group
                label="Brand Ref *"
                label-for="ref"
              >
                <b-form-input
                  id="ref"
                  v-model="formData.ref"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :disabled="!canEditStyle"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Ref -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Manufacturer Ref"
              label-for="manufacturer-ref"
            >
              <b-form-input
                id="manufacturer-ref"
                v-model="formData.manufacturer_ref"
                autofocus
                trim
                :disabled="!canEditStyle"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Category -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Category"
              rules="required"
            >
              <b-form-group
                label="Category *"
                label-for="style-category"
              >
                <v-select
                  v-model="formData.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoryOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="style-category"
                  :disabled="!canEditStyle"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Collection -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Collection"
              label-for="style-collection"
            >
              <v-select
                v-model="formData.collection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="collectionFilteredOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="style-collection"
                :disabled="!canEditStyle"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Brand -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="Brand"
              rules="required"
            >
              <b-form-group
                label="Brand *"
                label-for="style-brand"
              >
                <v-select
                  v-model="formData.brand"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="brandOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :disabled="!canEditStyle || isBrand"
                  input-id="style-brand"
                  @input="refreshBrandOptions"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Brand Contact -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Brand Contact"
              label-for="style-brand-contact"
            >
              <v-select
                v-model="formData.brand_contact_user"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="brandContactUserFilteredOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="style-brand-contact"
                :disabled="!canEditStyle"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Agent -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Agent"
              label-for="style-agent"
            >
              <v-select
                v-model="formData.agent"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="agentOptions"
                :reduce="val => val.value"
                :clearable="true"
                :disabled="!canEditStyle || isAgent"
                input-id="style-agent"
                @input="refreshAgentOptions"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Agent Contact -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Agent Contact"
              label-for="style-agent-contact"
            >
              <v-select
                v-model="formData.agent_contact_user"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="agentContactUserFilteredOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="style-agent-contact"
                :disabled="!canEditStyle"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Manufacturer -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Manufacturer"
              label-for="style-manufacturer"
            >
              <v-select
                v-model="formData.manufacturer"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="manufacturerOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="style-manufacturer"
                :disabled="!canEditStyle || isManufacturer"
                @input="refreshManufacturerOptions"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Manufacturer Contact -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Manufacturer Contact"
              label-for="style-manufacturer-contact"
            >
              <v-select
                v-model="formData.manufacturer_contact_user"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="manufacturerContactUserFilteredOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="style-manufacturer-contact"
                :disabled="!canEditStyle"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="submit"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="invalid || !canEditStyle"
        >
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="!canEditStyle"
        >
          Reset
        </b-button>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
    canEditStatus: {
      type: Boolean,
      required: true,
    },
    canEditStyle: {
      type: Boolean,
      required: true,
    },
    workflowStatusOptions: {
      type: Array,
      required: true,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    agentOptions: {
      type: Array,
      required: true,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    collectionOptions: {
      type: Array,
      required: true,
    },
    manufacturerOptions: {
      type: Array,
      required: true,
    },
    agentContactUserOptions: {
      type: Array,
      required: true,
    },
    brandContactUserOptions: {
      type: Array,
      required: true,
    },
    manufacturerContactUserOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const formData = ref({ ...props.styleData })
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isBrand = userRole === 'brand-manager' || userRole === 'brand'
    const isManufacturer = userRole === 'manufacturer-manager' || userRole === 'manufacturer'
    const isAgent = userRole === 'agent-manager' || userRole === 'agent'
    const collectionFilteredOptions = ref([])
    const agentContactUserFilteredOptions = ref([])
    const brandContactUserFilteredOptions = ref([])
    const manufacturerContactUserFilteredOptions = ref([])

    watch(() => props.styleData.status, newValue => {
      if (newValue !== undefined) {
        formData.value.status = newValue
      }
    }, { deep: true, immediate: false })

    const onSubmit = () => {
      const styleData = {
        id: router.currentRoute.params.id,
        ...formData.value,
      }
      store.dispatch('app-style/editStyle', styleData)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Style edited',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          emit('fetch-style')
        })
    }

    const initOptions = () => {
      if (isBrand) {
        collectionFilteredOptions.value = [
          ...props.collectionOptions,
        ]
        brandContactUserFilteredOptions.value = [
          ...props.brandContactUserOptions,
        ]
      } else {
        collectionFilteredOptions.value = props.collectionOptions
          .filter(option => (option.brand === props.styleData.brand))
        brandContactUserFilteredOptions.value = props.brandContactUserOptions
          .filter(option => (option.brand === props.styleData.brand))
      }
      if (isManufacturer) {
        manufacturerContactUserFilteredOptions.value = [
          ...props.manufacturerContactUserOptions,
        ]
      } else {
        manufacturerContactUserFilteredOptions.value = props.manufacturerContactUserOptions
          .filter(option => (option.manufacturer === props.styleData.manufacturer))
      }
      if (isAgent) {
        agentContactUserFilteredOptions.value = [
          ...props.agentContactUserOptions,
        ]
      } else {
        agentContactUserFilteredOptions.value = props.agentContactUserOptions
          .filter(option => (option.agent === props.styleData.agent))
      }
    }
    initOptions()

    const resetFormData = () => {
      formData.value = { ...props.styleData }
      initOptions()
    }

    const refreshAgentOptions = agent => {
      agentContactUserFilteredOptions.value = props.agentContactUserOptions
        .filter(option => (option.agent === agent))

      formData.value = {
        ...formData.value,
        agent_contact_user: '',
      }
    }

    const refreshBrandOptions = brand => {
      collectionFilteredOptions.value = props.collectionOptions
        .filter(option => (option.brand === brand))
      brandContactUserFilteredOptions.value = props.brandContactUserOptions
        .filter(option => (option.brand === brand))

      formData.value = {
        ...formData.value,
        collection: '',
        brand_contact_user: '',
      }
    }

    const refreshManufacturerOptions = manufacturer => {
      manufacturerContactUserFilteredOptions.value = props.manufacturerContactUserOptions
        .filter(option => (option.manufacturer === manufacturer))

      formData.value = {
        ...formData.value,
        manufacturer_contact_user: '',
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormData)

    return {
      onSubmit,
      formData,
      isBrand,
      isManufacturer,
      isAgent,

      collectionFilteredOptions,
      agentContactUserFilteredOptions,
      brandContactUserFilteredOptions,
      manufacturerContactUserFilteredOptions,
      refreshAgentOptions,
      refreshBrandOptions,
      refreshManufacturerOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
