<template>
  <div class="d-flex flex-row align-items-center justify-content-between">
    <div class="d-flex flex-column">
      <div class="font-weight-bold">
        {{ data.item.status }}
      </div>
      <div
        v-if="showDate(data.item.status, 'requested_date')"
      >
        Requested: {{ data.item.requested_date }}
      </div>
      <div
        v-if="showDate(data.item.status, 'deadline_date')"
      >
        Deadline: {{ data.item.deadline_date }}
      </div>
      <div
        v-if="showDate(data.item.status, 'sent_date')"
      >
        Sent: {{ data.item.sent_date }}
      </div>
      <div
        v-if="showDate(data.item.status, 'approved_date')"
      >
        Approved: {{ data.item.approved_date }}
      </div>
      <div
        v-if="showDate(data.item.status, 'rejected_date')"
      >
        Rejected: {{ data.item.rejected_date }}
      </div>
    </div>
    <div>
      <b-dropdown
        variant="link"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
          />
        </template>
        <b-dropdown-group
          header="Change Status"
        >
          <b-dropdown-item
            v-for="option in workflowStatusOptions.filter(
              option => option.value != data.item.status)"
            :key="option.value"
            @click="setStatus(data.item, option.value)"
          >
            <span>{{ option.label }}</span>
          </b-dropdown-item>
        </b-dropdown-group>
        <b-dropdown-group
          v-if="data.item.status in workflowStatusDates"
          header="Edit Current Status"
        >
          <b-dropdown-item
            @click="setStatus(data.item, data.item.status)"
          >
            <span>Edit {{ data.item.status }}</span>
          </b-dropdown-item>
        </b-dropdown-group>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownGroup, BDropdownItem,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import styleStoreModule from '../styleStoreModule'
import useStyleMaterialsList from './useStyleMaterialsList'

export default {
  components: {
    BDropdown,
    BDropdownGroup,
    BDropdownItem,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    workflowStatusDates: {
      type: Object,
      required: true,
    },
    workflowStatusOptions: {
      type: Array,
      required: true,
    },
  },
  emits: ['refetch-data', 'open-status-form'],
  setup(props, { emit, root }) {
    const STYLE_APP_STORE_MODULE_NAME = 'app-style'
    const toast = useToast()

    // Register module
    if (!root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      root.$store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        root.$store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const {
      showDate,
    } = useStyleMaterialsList()

    const setStatus = (material, status) => {
      const { id } = material

      if (status in props.workflowStatusDates) {
        emit('open-status-form', material, status)
      } else {
        store.dispatch('app-style/updateStyleMaterial', { id, status })
          .then(() => {
            emit('refetch-data')
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Status updated to ${status}`,
                icon: 'InfoIcon',
                variant: 'success',
              },
            })
          })
      }
    }

    return {
      showDate,
      setStatus,
    }
  },
}
</script>
