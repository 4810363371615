<template>
  <div
    class="d-flex flex-fill flex-row mb-0"
  >
    <feather-icon
      :icon="icon"
      size="16"
      class="mr-0 mr-sm-50"
    />
    <h5
      :class="notApplicable ? '': approved ? 'text-success': 'text-danger'"
    >{{ title }}</h5>

    <div
      v-if="notApplicable"
      class="pl-1"
    >Not Applicable</div>
    <template
      v-else
    >
      <div
        v-if="approved"
        class="pl-1 text-success"
      >Approved</div>
      <div
        v-else
        class="pl-1 text-danger"
      >Not Approved</div>
    </template>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    approved: {
      type: Boolean,
      required: true,
    },
    notApplicable: {
      type: Boolean,
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
