<template>
  <div class="flex-fill d-flex flex-column m-1">
    <title-header
      v-if="feedConfig.headers[type] === 'title-header'"
      :title="feedVars.custom_title || type"
    />

    <approval-header
      v-if="feedConfig.headers[type] === 'approval-header'"
      :title="type"
      :approved="feedVars.approved"
      :not-applicable="feedVars.not_applicable"
      :icon="feedConfig.icons[type]"
    />

    <div class="d-flex flex-fill flex-row">
      <div class="mr-1">
        <b-avatar
          size="42"
          :src="createdBy.photo"
        />
      </div>
      <div class="d-flex flex-fill flex-column">
        <div class="d-flex flex-row">
          <b>{{ createdBy.full_name || createdBy.email }}</b>
          <span
            v-if="createdBy.job_description"
            class="pl-1"
          >({{ createdBy.job_description }})</span>
          <div
            class="pl-1"
          >
            <i class="text-secondary">{{ createdAt }}</i>
          </div>
        </div>

        <div v-if="feedConfig.feedBody[type] === 'comments-body'">
          <comments-body
            :comments="feedVars.comments"
            :image="image"
          />
        </div>
        <div v-else-if="feedConfig.feedBody[type] === 'measurement-comments-body'">
          <measurement-comments-body
            :measurements="feedVars.measurements"
            :comments="feedVars.comments"
            :sample-size="feedVars.sample_size"
            :image="image"
          />
        </div>
        <div v-else-if="feedConfig.feedBody[type] === 'new-sample-request-body'">
          <new-sample-request-body
            :sample-type="feedVars.sample_type"
            :deadline="feedVars.deadline"
          />
        </div>
        <div v-else-if="feedConfig.feedBody[type] === 'sample-requested-body'">
          <sample-requested-body />
        </div>
        <div v-else-if="feedConfig.feedBody[type] === 'sample-received-body'">
          <sample-received-body />
        </div>
        <div v-else-if="feedConfig.feedBody[type] === 'sample-confirmed-body'">
          <sample-confirmed-body
            :estimate="feedVars.estimate"
          />
        </div>
        <div v-else-if="feedConfig.feedBody[type] === 'sample-ready-to-make-body'">
          <sample-ready-to-make-body />
        </div>
        <div v-else-if="feedConfig.feedBody[type] === 'sample-sent-body'">
          <sample-sent-body
            :tracking-info="feedVars.tracking_info"
          />
        </div>
        <div v-else-if="feedConfig.feedBody[type] === 'sample-updated-body'">
          <sample-updated-body
            :deadline="feedVars.deadline"
            :estimate="feedVars.estimate"
            :sample-type="feedVars.sample_type"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
} from 'bootstrap-vue'
import ApprovalHeader from './ApprovalHeader.vue'
import TitleHeader from './TitleHeader.vue'
import CommentsBody from './CommentsBody.vue'
import MeasurementCommentsBody from './MeasurementCommentsBody.vue'
import NewSampleRequestBody from './NewSampleRequestBody.vue'
import SampleRequestedBody from './SampleRequestedBody.vue'
import SampleReceivedBody from './SampleReceivedStatusBody.vue'
import SampleConfirmedBody from './SampleConfirmedBody.vue'
import SampleSentBody from './SampleSentBody.vue'
import SampleReadyToMakeBody from './SampleReadyToMakeBody.vue'
import SampleUpdatedBody from './SampleUpdatedBody.vue'

export default {
  components: {
    BAvatar,

    ApprovalHeader,
    TitleHeader,
    CommentsBody,
    MeasurementCommentsBody,
    NewSampleRequestBody,
    SampleRequestedBody,
    SampleReceivedBody,
    SampleConfirmedBody,
    SampleReadyToMakeBody,
    SampleSentBody,
    SampleUpdatedBody,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    createdAt: {
      type: String,
      required: true,
    },
    createdBy: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
      required: false,
      default: '',
    },
    feedVars: {
      type: Object,
      required: true,
    },
    feedConfig: {
      type: Object,
      required: true,
    },
  },
}
</script>
