<template>
  <div class="d-flex justify-content-between">
    <span>{{ label }}</span>

    <feather-icon
      v-if="notApplicable"
      icon="CircleIcon"
      size="16"
      class="mx-1"
    />
    <template
      v-else
    >
      <feather-icon
        v-if="approved"
        icon="CircleIcon"
        size="16"
        class="mx-1 text-success"
      />
      <feather-icon
        v-else
        icon="CircleIcon"
        size="16"
        class="mx-1 text-danger"
      />
    </template>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    approved: {
      type: Boolean,
      required: true,
    },
    notApplicable: {
      type: Boolean,
    },

  },
}
</script>
