<template>

  <div>
    <div>

      <b-card
        no-body
        class="mb-0"
      >

        <div class="mb-2">
          <div class="d-flex align-items-center justify-content-start">
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  class="bg-light"
                >Select Category
                </b-input-group-text>
              </template>
              <b-form-select
                v-model="categoryType"
                :options="categoryTypeOptions"
                :disabled="!canEditStyle"
                rounded="0"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  rounded="right"
                  :to="{ name: 'apps-styles-add-material',
                         params: { id: currentId, category: categoryTypeValue } }"
                  :disabled="!canEditStyle"
                >
                  <span class="text-nowrap">Add Trim</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <b-table
          ref="refMaterialListTable"
          class="position-relative"
          :items="fetchStyleTrims"
          responsive
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          primary-key="id"
          show-empty
          empty-text="Without Materials"
          style="min-height: 140px; overflow: visible;"
        >

          <!-- Column: Category type -->
          <template #cell(category_type)="data">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                {{ data.item.category_type }}
              </div>
              <div>
                <b-avatar
                  v-for="media in imageMedia(data.item.style_material_media)"
                  :key="media.id"
                  :src="media.image_thumbnail"
                  :style="{width: '3em', height: '3em'}"
                  :title="media.name"
                  square
                  class="mr-1"
                />
              </div>
              <div class="d-flex">
                <b-img
                  v-for="color in data.item.colors"
                  :key="color.id"
                  blank
                  :alt="color.name"
                  :blank-color="color.color"
                  :style="{width: '1.5em', height: '1.5em'}"
                  :title="color.name"
                  class="mr-1"
                  rounded="circle"
                />
              </div>
            </div>
          </template>

          <!-- Column: Ref -->
          <template #cell(description)="data">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <div
                  class="align-text-top"
                  style="white-space: pre-line"
                >
                  {{ data.item.specifics_description }}
                </div>
                <div class="align-text-top">
                  {{ data.item.dimensions_description }}
                </div>
              </div>
              <feather-icon
                v-if="data.item.placement"
                v-b-tooltip.hover
                icon="TargetIcon"
                class="d-inline mr-1"
                :title="data.item.placement"
              />
              <feather-icon
                v-if="data.item.feed && data.item.feed.length != 0"
                icon="MessageCircleIcon"
                class="d-inline mr-1"
              />
            </div>
          </template>

          <!-- Column: Status -->
          <template #head(status)="">
            <div>Trims Status</div>
          </template>

          <template #cell(status)="data">
            <material-status-cell
              :data="data"
              :workflow-status-options="workflowStatusOptions"
              :workflow-status-dates="workflowStatusDates"
              @refetch-data="refetchData"
              @open-status-form="openStatusForm"
            />
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              :disabled="!canEditStyle"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'apps-styles-edit-material',
                       params: { id: currentId, material: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="onMaterialDelete(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="onComment(data.item)"
              >
                <feather-icon icon="MessageCircleIcon" />
                <span class="align-middle ml-50">Add Comment</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </div>

    <b-modal
      v-model="showCommentsForm"
      ok-only
      ok-title="Add"
      :ok-disabled="!comments.trim()"
      :title="selectedMaterialTitle"
      @ok="addComments"
    >
      <b-form
        class="p-2"
      >
        <b-form-group
          label="Comment"
          label-for="comment"
        >
          <b-form-textarea
            id="comment"
            v-model="comments"
            trim
            rows="3"
            max-rows="5"
            required
            autofocus
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      v-model="showDeleteMaterialModal"
      ok-title="Delete"
      title="Delete Material"
      @ok="deleteMaterial"
    >
      Are you sure that you want to delete
      <b>{{ selectedMaterialTitle }}</b>?
    </b-modal>
    <material-status-form
      :workflow-status-options="workflowStatusOptions"
      :workflow-status-dates="workflowStatusDates"
      :selected-material="selectedMaterial"
      :workflow-status="workflowStatus"
      :show-status-form="showStatusForm"
      @refetch-data="refetchData"
      @close-status-form="closeStatusForm"
    />
  </div>
</template>

<script>
import {
  BAvatar, BButton, BCard, BDropdown, BDropdownItem, BForm,
  BFormGroup, BFormSelect, BFormTextarea,
  BImg, BInputGroup, BInputGroupAppend, BInputGroupText, BModal, BTable,
} from 'bootstrap-vue'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import { required } from '@validations'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useStyleMaterialsList from './useStyleMaterialsList'
import styleStoreModule from '../styleStoreModule'
import MaterialStatusCell from './MaterialStatusCell.vue'
import MaterialStatusForm from './MaterialStatusForm.vue'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BModal,
    BTable,

    MaterialStatusCell,
    MaterialStatusForm,
  },
  props: {
    styleData: {
      type: Object,
      required: true,
    },
    colorOptions: {
      type: Array,
      required: true,
    },
    categoryTypeOptions: {
      type: Array,
      required: true,
    },
    workflowStatusOptions: {
      type: Array,
      required: true,
    },
    workflowStatusDates: {
      type: Object,
      required: true,
    },
    canEditStyle: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['scroll-to-bottom'],
  expose: ['feed'],
  data() {
    return {
      required,
    }
  },
  setup(props, { emit, root }) {
    const STYLE_APP_STORE_MODULE_NAME = 'app-style'
    const toast = useToast()

    // Register module
    if (!root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      root.$store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (root.$store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        root.$store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const categoryType = ref('Label')

    const showCommentsForm = ref(false)
    const showDeleteMaterialModal = ref(false)
    const showStatusForm = ref(false)
    const selectedMaterialId = ref('')
    const selectedMaterialTitle = ref('')
    const selectedMaterial = ref(null)
    const workflowStatus = ref('')
    const comments = ref('')

    const categoryTypeValue = computed(() => categoryType.value
      .toLowerCase().replace(' ', '-'))

    const currentId = computed(() => router.currentRoute.params.id)

    const {
      fetchStyleTrims,
      tableColumns,
      refMaterialListTable,
      sortBy,
      isSortDirDesc,
      refetchData,

      // categoryTypeFilter,
    } = useStyleMaterialsList()

    const feed = categoryTypeFilter => {
      const allFeeds = []
      if (refMaterialListTable.value) {
        refMaterialListTable.value.localItems.forEach(material => {
          if (categoryTypeFilter === undefined
              || categoryTypeFilter === 'All'
              || categoryTypeFilter === material.category_type) {
            material.feed.forEach(materialFeed => {
              allFeeds.push(materialFeed)
            })
          }
        })
      }
      return allFeeds.sort((a, b) => a.id - b.id)
    }

    const imageMedia = allMedia => allMedia.filter(media => media.image_thumbnail)

    const addComments = () => {
      const feedData = new FormData()
      feedData.append('style_material', selectedMaterialId.value)
      feedData.append('type', 'Comment')
      feedData.append('feed_vars', JSON.stringify({
        comments: comments.value,
        custom_title: selectedMaterialTitle.value,
      }))
      store.dispatch('app-style/addStyleMaterialFeed', feedData)
        .then(() => {
          refetchData()
          comments.value = ''
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Comment added',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          emit('scroll-to-bottom')
        })
    }

    const onMaterialSelect = material => {
      selectedMaterialId.value = material.id
      selectedMaterialTitle.value = material.category_type
      if (material.specifics_description) {
        selectedMaterialTitle.value += ` - ${material.specifics_description}`
      }
      selectedMaterial.value = material
    }

    const onComment = material => {
      onMaterialSelect(material)
      showCommentsForm.value = true
    }

    const deleteMaterial = () => {
      store.dispatch('app-style/deleteStyleMaterial', {
        id: selectedMaterialId.value,
      })
        .then(() => {
          refetchData()
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Material deleted',
              icon: 'Trash2Icon',
              variant: 'danger',
            },
          })
        })
    }

    const onMaterialDelete = material => {
      onMaterialSelect(material)
      showDeleteMaterialModal.value = true
    }

    const openStatusForm = (material, status) => {
      selectedMaterial.value = material
      workflowStatus.value = status
      showStatusForm.value = true
    }
    const closeStatusForm = () => {
      showStatusForm.value = false
    }

    return {
      fetchStyleTrims,
      onMaterialSelect,
      onMaterialDelete,
      onComment,
      addComments,
      deleteMaterial,
      openStatusForm,
      closeStatusForm,
      feed,

      tableColumns,
      refetchData,
      sortBy,
      isSortDirDesc,
      refMaterialListTable,
      categoryType,
      categoryTypeValue,
      currentId,
      showCommentsForm,
      showDeleteMaterialModal,
      showStatusForm,
      workflowStatus,
      comments,
      selectedMaterial,
      selectedMaterialId,
      selectedMaterialTitle,
      imageMedia,
    }
  },
}
</script>
